<div class="bg-[#fff] rounded-2xl px-8 py-10 mx-auto mt-20 sm:mt-0 sm:w-1/2">
  <form
    [nzLabelWrap]="true"
    nz-form
    nzLabelAlign="left"
    nzLayout="vertical"
    [formGroup]="form"
  >
    <nz-row [nzGutter]="24">
      <nz-col [nzSm]="24" [nzXs]="24">
        <nz-form-label [nzXs]="24" nzFor="nameEnterprise" nzRequired>
          Choix du type de rapport</nz-form-label
        >
        <nz-form-control nzErrorTip="Le type de rapport est requis">
          <nz-input-group nzSize="large">
            <nz-select
              [nzAllowClear]="true"
              formControlName="reportType"
              nzPlaceHolder="Choix du type"
            >
              <nz-option
                *ngFor="let item of REPORT_TYPES"
                [nzLabel]="item.label"
                [nzValue]="item.value"
              ></nz-option>
            </nz-select> </nz-input-group
        ></nz-form-control>
      </nz-col>

      <nz-col class="mt-6 mb-10" [nzSm]="24" [nzXs]="24">
        <nz-form-label [nzXs]="24" nzFor="nameEnterprise" nzRequired>
          Intervalle d'activité</nz-form-label
        >
        <nz-form-control>
          <nz-input-group id="nameEnterprise" nzSize="large">
            <weni-custom-date-range-picker
              [variant]="'white'"
              (dateRangeChange)="onFilterWithDate($event)"
            ></weni-custom-date-range-picker> </nz-input-group
        ></nz-form-control>
      </nz-col>
    </nz-row>

    <div class="mx-auto flex justify-center">
      <weni-primary-action-button
        label="Générer et télécharger"
        variant="primary"
        size="md"
        variant="primary"
        [disabled]="form.invalid || isLoading"
        (click)="onSubmit()"
      ></weni-primary-action-button>
    </div>
  </form>
</div>
