import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SubMenuItemComponent } from './components/sub-menu-item/sub-menu-item.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { getModalWidth } from 'src/app/core/constantes';
import { NzModalService } from 'ng-zorro-antd/modal';
import { StateIndexComponent } from 'src/app/pages/state/state-index/state-index.component';
import { MENU_ITEMS, MenuItem } from './routes';
import { MobileMenuComponent } from 'src/app/shared/drawers/mobile-menu/mobile-menu.component';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'weni-menu-list',
  standalone: true,
  imports: [
    CommonModule,
    SubMenuItemComponent,
    MenuItemComponent,
    NzDropDownModule,
    NzIconModule,
    RouterModule,
  ],
  templateUrl: './menu-list.component.html',
})
export class MenuListComponent {
  @ViewChild('navList') navList!: ElementRef;
  isOpen = false;
  canScrollLeft = false;
  canScrollRight = false;
  activeMenuId: string | null = null;
  menuItems = MENU_ITEMS;
  private drawerRef: NzDrawerRef | null = null;
  constructor(
    private router: Router,
    private drawerService: NzDrawerService,
    private modalService: NzModalService
  ) {
    document.addEventListener('click', (event: Event) => {
      const target = event.target as HTMLElement;
      if (!target.closest('nav')) {
        this.closeMenu();
      }
    });
  }

  openStateModal(): void {
    // Debug log
    try {
      const modalRef = this.modalService.create({
        nzContent: StateIndexComponent,
        nzFooter: null,
        nzWidth: '80%',
        nzClassName: 'custom-modal-class',
        nzCentered: true,
        nzClosable: true,
        nzMaskClosable: true,
      });

      modalRef.afterOpen.subscribe(() => {
        // Debug log
      });

      modalRef.afterClose.subscribe((result) => {
        // Debug log
      });
    } catch (error) {
      console.error("Erreur lors de l'ouverture du modal:", error); // Debug log
    }
  }

  handleMenuItemClick(item: any): void {
    // Debug log
    if (item.action) {
      item.action();
    } else if (item.link) {
      this.router.navigate(item.link);
    }
    this.closeMenu();
  }

  @HostListener('window:resize')
  onResize() {
    this.checkScroll();
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }
  toggleSubMenu(item: MenuItem) {
    item.isOpen = !item.isOpen;
  }

  closeMenu(): void {
    this.isOpen = false;
  }

  scroll(direction: 'left' | 'right') {
    const scrollAmount = 200;
    if (this.navList) {
      const element = this.navList.nativeElement;
      if (direction === 'left') {
        element.scrollLeft -= scrollAmount;
      } else {
        element.scrollLeft += scrollAmount;
      }
      this.checkScroll();
    }
  }

  checkScroll() {
    if (this.navList) {
      const element = this.navList.nativeElement;
      this.canScrollLeft = element.scrollLeft > 0;
      this.canScrollRight =
        element.scrollLeft < element.scrollWidth - element.clientWidth;
    }
  }

  ngAfterViewInit() {
    this.checkScroll();
  }

  handleMenuToggle(menuId: string) {
    this.activeMenuId = this.activeMenuId === menuId ? null : menuId;
  }

  toggleMobileMenu() {
    let modalWidth;
    modalWidth = getModalWidth();
    this.drawerRef = this.drawerService.create<MobileMenuComponent>({
      nzTitle: '',
      nzWidth: modalWidth,
      nzPlacement: 'left',
      nzClosable: false,
      nzCloseOnNavigation: true,
      nzContent: MobileMenuComponent,
      nzContentParams: {},
    });

    this.drawerRef.afterClose.subscribe((response) => {});
  }
}
