import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../interfaces/IResponse';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { API_RESPONSE_STATUS } from '../enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedAdmin: BehaviorSubject<any> = new BehaviorSubject(null);

  get loggedAdmin$(): Observable<any> {
    return this.loggedAdmin.asObservable();
  }
  constructor(private http: HttpClient, private utils: UtilsService) {}

  loginAdmin(data: any): Observable<IResponse<any>> {
    this.utils.RemoveToken();
    return this.http
      .post<IResponse<any>>(
        `${environment.baseUrl}/administrator/security/login`,
        data
      )
      .pipe(
        map((res: IResponse<any>) => {
          if (
            res.statusCode === API_RESPONSE_STATUS.SUCCESS &&
            res.data.accessToken !== null
          ) {
            this.utils.SetToken(res.data.accessToken);
          }
          return res;
        })
      );
  }

  public getProfile(): Observable<IResponse<any>> {
    return this.http
      .get<IResponse<any>>(
        `${environment.baseUrl}/administrator/security/profile`
      )
      .pipe(
        tap((res: IResponse<any>) => {
          if (res.statusCode === API_RESPONSE_STATUS.SUCCESS) {
            this.loggedAdmin.next(res.data.user);
          }
        })
      );
  }

  updateProfile(data: any): Observable<IResponse<any>> {
    return this.http.put<IResponse<any>>(
      `${environment.baseUrl}/administrator/security/profile`,
      data
    );
  }

  requestPasswordEmail(data: any): Observable<IResponse<any>> {
    return this.http
      .post<IResponse<any>>(
        `${environment.baseUrl}/administrator/security/request-forgot-password`,
        data
      )
      .pipe(
        map((res: IResponse<any>) => {
          return res;
        })
      );
  }
}
