import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Decrypt, Encrypt } from '../utils/encode-decode';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { IPowerPassword } from '../interfaces/IPowerPassword';
import { LOCAL_STORAGE_KEY } from '../constantes';
import { mappingRoutes } from '../mappingRoutes';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  /**
   * Account Object
   * @private
   */
  private _compteData$: BehaviorSubject<any> = new BehaviorSubject(null);

  /**************************
   * AUTHENTICATION SECTION *
   **************************/
  /**
   * Token value
   * @private
   */
  private _token: string | null = null;
  /**
   * Page Title
   * @private
   */
  private _pageTitle$: BehaviorSubject<string> = new BehaviorSubject('Accueil');

  constructor(
    private nzNotificationService: NzNotificationService,
    private nzModalService: NzModalService,
    private router: Router
  ) {}

  public get compteData$(): BehaviorSubject<any | null> {
    return this._compteData$;
  }

  public set compteData$(value: any) {
    this._compteData$.next(value);
  }

  get token(): string | null {
    return this._token;
  }

  set token(value: string | null) {
    this._token = value;
  }

  public get pageTitle$(): BehaviorSubject<string> {
    return this._pageTitle$;
  }

  public set pageTitle$(value: any) {
    this._pageTitle$.next(value);
  }

  /**
   * Get Token
   * @constructor
   */
  public GetToken(): string | null {
    return Decrypt(localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN));
  }

  /**
   * Set Token
   * @param token
   * @constructor
   */
  public SetToken(token: string): void {
    localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, Encrypt(token));
  }

  /**
   * Remove token
   * @constructor
   */
  public RemoveToken(): void {
    localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
  }

  /**************
   * PAGE TITLE *
   **************/

  /**
   * Disconnect an user
   * @constructor
   */
  public Disconnect(): void {
    this.RemoveToken();
    this.token = null;
    this.compteData$ = null;
  }

  /**
   * Disconnect a user with confirmation
   * @constructor
   */
  public LogOut(): void {
    this.nzModalService.confirm({
      nzTitle: 'Confirmez-vous la déconnexion ?',
      nzContent: `<span>Souhaitez vous vraiment quitter l’application?</span>
                  <em>Cliquez sur annuler pour interrompre la déconnexion</em>`,
      nzOnOk: () => {
        this.Disconnect();
        this.router.navigate([mappingRoutes.auth.login]).then(() => {});
      },
      nzOkDanger: false,
      nzOkText: `Se déconnecter`,
    });
  }

  public GetPasswordIndicator(
    power: IPowerPassword,
    powerPwd: IPowerPassword,
    colsPower: Map<
      IPowerPassword,
      {
        power: number;
        className: string;
      }
    >
  ): string | undefined {
    return (colsPower.get(powerPwd)?.power || 0) == colsPower.get(power)?.power
      ? colsPower.get(power)?.className
      : (colsPower.get(powerPwd)?.power || 0) > colsPower.get(power)?.power!
      ? colsPower.get(powerPwd)?.className
      : '';
  }

  public hasToken() {
    return this.GetToken() !== null;
  }
}
