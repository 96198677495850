export const environment = {
  production: true,
  //baseUrl: 'https://api.weni.ci',
  owner: 'PRO_DELIVERY',
  baseUrl: 'https://api.prodelivery.pro',
  defaultauth: '',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
};
