export function formatCustomStartDate(inputDate: string) {
  const date = new Date(inputDate);
  const formattedDate = `${date.toISOString().split('T')[0]}T00:00:00`;
  return formattedDate;
}
export function formatCustomEndDate(inputDate: string) {
  const date = new Date(inputDate);
  const formattedDate = `${date.toISOString().split('T')[0]}T23:59:59`;
  return formattedDate;
}

export function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function formatJsDate(date: Date | string | number): string {
  // Convertir l'entrée en objet Date si ce n'est pas déjà le cas
  const dateObj = date instanceof Date ? date : new Date(date);

  // Vérifier si la date est valide
  if (isNaN(dateObj.getTime())) {
    return ''; // Ou une date par défaut
  }

  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
