import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormGroup,
  FormBuilder,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';

@Component({
  selector: 'weni-custom-date-range-picker',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, CommonModule],
  templateUrl: './custom-date-range-picker.component.html',
  styleUrl: './custom-date-range-picker.component.less',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDateRangePickerComponent),
      multi: true,
    },
  ],
})
export class CustomDateRangePickerComponent
  implements OnInit, ControlValueAccessor
{
  @Output() dateRangeChange = new EventEmitter<{
    start: Date | null;
    end: Date | null;
  }>();
  @Input('variant') variant: string | null = null;

  isCalendarVisible = false;
  weekDays = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
  months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  years: number[] = [];
  calendarDays: Array<{ date: Date; dayNumber: number; enabled: boolean }> = [];

  currentMonth: number;
  currentYear: number;
  startDate: Date | null = null;
  endDate: Date | null = null;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  constructor(private elementRef: ElementRef) {
    const now = new Date();
    this.currentMonth = now.getMonth();
    this.currentYear = now.getFullYear();

    // Générer la liste des années (de -5 ans à +5 ans par rapport à l'année courante)
    const currentYear = now.getFullYear();
    this.years = Array.from({ length: 11 }, (_, i) => currentYear - 5 + i);
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isCalendarVisible = false;
    }
  }

  ngOnInit() {
    this.generateCalendar();
  }

  toggleCalendar() {
    this.isCalendarVisible = !this.isCalendarVisible;
    if (this.isCalendarVisible) {
      this.generateCalendar();
    }
  }

  generateCalendar() {
    const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1);
    const lastDayOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);

    this.calendarDays = [];

    // Jours du mois précédent
    const firstDayWeekday = firstDayOfMonth.getDay();
    const previousMonthLastDay = new Date(
      this.currentYear,
      this.currentMonth,
      0
    ).getDate();

    for (let i = firstDayWeekday - 1; i >= 0; i--) {
      const date = new Date(
        this.currentYear,
        this.currentMonth - 1,
        previousMonthLastDay - i
      );
      this.calendarDays.push({
        date,
        dayNumber: previousMonthLastDay - i,
        enabled: false,
      });
    }

    // Jours du mois courant
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      const date = new Date(this.currentYear, this.currentMonth, i);
      this.calendarDays.push({
        date,
        dayNumber: i,
        enabled: true,
      });
    }

    // Jours du mois suivant
    const remainingDays = 42 - this.calendarDays.length;
    for (let i = 1; i <= remainingDays; i++) {
      const date = new Date(this.currentYear, this.currentMonth + 1, i);
      this.calendarDays.push({
        date,
        dayNumber: i,
        enabled: false,
      });
    }
  }

  selectDate(day: { date: Date; dayNumber: number; enabled: boolean }) {
    if (!day.enabled) return;

    if (!this.startDate || (this.startDate && this.endDate)) {
      this.startDate = day.date;
      this.endDate = null;
    } else {
      if (day.date < this.startDate) {
        this.endDate = this.startDate;
        this.startDate = day.date;
      } else {
        this.endDate = day.date;
      }
      this.isCalendarVisible = false;
    }

    this.onChange({ start: this.startDate, end: this.endDate });
    this.dateRangeChange.emit({ start: this.startDate, end: this.endDate });
    this.onTouched();
  }

  previousMonth() {
    if (this.currentMonth === 0) {
      this.currentMonth = 11;
      this.currentYear--;
    } else {
      this.currentMonth--;
    }
    this.generateCalendar();
  }

  nextMonth() {
    if (this.currentMonth === 11) {
      this.currentMonth = 0;
      this.currentYear++;
    } else {
      this.currentMonth++;
    }
    this.generateCalendar();
  }

  onMonthChange() {
    this.generateCalendar();
  }

  onYearChange() {
    this.generateCalendar();
  }

  setToday() {
    const today = new Date();
    this.currentMonth = today.getMonth();
    this.currentYear = today.getFullYear();
    this.generateCalendar();
  }

  clearSelection() {
    this.startDate = null;
    this.endDate = null;
    this.onChange({ start: null, end: null });
    this.dateRangeChange.emit({ start: null, end: null });
    this.isCalendarVisible = false;
  }

  isSelected(date: Date): boolean {
    return (
      this.isSameDay(date, this.startDate) || this.isSameDay(date, this.endDate)
    );
  }

  isInRange(date: Date): boolean {
    if (!this.startDate || !this.endDate) return false;
    return date > this.startDate && date < this.endDate;
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return this.isSameDay(date, today);
  }

  isSameDay(date1: Date | null, date2: Date | null): boolean {
    if (!date1 || !date2) return false;
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  formatDateRange(): string {
    if (!this.startDate) return '';
    if (!this.endDate) return this.formatDate(this.startDate);
    return `${this.formatDate(this.startDate)} - ${this.formatDate(
      this.endDate
    )}`;
  }

  formatDate(date: Date): string {
    return date.toLocaleDateString('fr-FR');
  }

  // Implémentation de ControlValueAccessor
  writeValue(value: any): void {
    if (value) {
      this.startDate = value.start;
      this.endDate = value.end;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
