// dropdown.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  private activeDropdownId = new BehaviorSubject<string | null>(null);
  activeDropdown$ = this.activeDropdownId.asObservable();

  setActiveDropdown(id: string | null) {
    this.activeDropdownId.next(id);
  }

  closeAllDropdowns() {
    this.activeDropdownId.next(null);
  }
}
