import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { RouterOutlet } from '@angular/router';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

@Component({
  selector: 'weni-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  standalone: true,
  imports: [RouterOutlet, NzLayoutModule],
})
export class AppComponent {}
