import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  input,
  OnInit,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LocalStorageServiceService } from '../services/local-storage-service.service';

@Component({
  selector: 'weni-menu-item',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.less',
})
export class MenuItemComponent implements OnInit {
  constructor(
    private cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageServiceService,
    private router: Router
  ) {}
  ngOnInit(): void {
    const currentUrl = this.router.url;
    if (this.item.link[0] === currentUrl) {
      this.handleClick();
    }
  }
  @Input('item') item!: any;

  handleClick() {
    this.localStorageService.clearState();
    this.cdr.markForCheck();
  }
}
