<div class="h-full overflow-y-auto">
  <weni-app-header></weni-app-header>

  <main class="lg:container lg:mx-auto w-full px-4 md:px-8 py-4 sm:py-8 mb-16">
    <div class="text-xl !mb-4">
      <nz-breadcrumb [nzAutoGenerate]="true" class="custom-breadcrumb">
        <ng-template nzBreadcrumbItemTemplate let-breadcrumb>
          <a
            [routerLink]="breadcrumb.url"
            class="transition-colors duration-200 hover:text-blue-600"
            [ngClass]="{
              'text-gray-400': !breadcrumb.url,
              'text-blue-500 font-medium':
                breadcrumb.url && breadcrumb.url === currentUrl,
              'text-gray-600': breadcrumb.url && breadcrumb.url !== currentUrl
            }"
          >
            {{ breadcrumb.label }}
          </a>
        </ng-template>
      </nz-breadcrumb>
    </div>
    <router-outlet></router-outlet>
  </main>

  <weni-app-footer></weni-app-footer>
</div>
