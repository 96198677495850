import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface NavigationState {
  dropdownId: string;
  menuId: string;
  timestamp: number;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageServiceService {
  private readonly STORAGE_KEY = 'NAVIGATION_STATE';
  private navigationStateSubject: BehaviorSubject<NavigationState | null>;

  constructor() {
    const initialState = this.getStoredState();
    this.navigationStateSubject = new BehaviorSubject<NavigationState | null>(
      initialState
    );
  }

  private getStoredState(): NavigationState | null {
    try {
      const storedData = localStorage.getItem(this.STORAGE_KEY);
      if (!storedData) return null;

      const parsedData = JSON.parse(storedData);
      // Vérification de la validité des données
      if (this.isValidNavigationState(parsedData)) {
        return parsedData;
      }
      // Si les données ne sont pas valides, on les supprime
      this.clearState();
      return null;
    } catch (error) {
      console.error('Erreur lors de la lecture du stockage local:', error);
      this.clearState();
      return null;
    }
  }

  private isValidNavigationState(data: any): data is NavigationState {
    return (
      data &&
      typeof data.dropdownId === 'string' &&
      typeof data.menuId === 'string' &&
      typeof data.timestamp === 'number'
    );
  }

  getNavigationState$(): Observable<NavigationState | null> {
    return this.navigationStateSubject.asObservable();
  }

  setNavigationState(dropdownId: string, menuId: string): void {
    const newState: NavigationState = {
      dropdownId,
      menuId,
      timestamp: Date.now(),
    };

    try {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(newState));
      this.navigationStateSubject.next(newState);
    } catch (error) {
      console.error(
        'Erreur lors de la sauvegarde dans le stockage local:',
        error
      );
    }
  }

  clearState(): void {
    try {
      localStorage.removeItem(this.STORAGE_KEY);
      this.navigationStateSubject.next(null);
    } catch (error) {
      console.error('Erreur lors de la suppression du stockage local:', error);
    }
  }

  isMenuActive(menuId: string): boolean {
    const currentState = this.navigationStateSubject.value;
    return currentState?.menuId === menuId;
  }

  isDropdownActive(dropdownId: string): boolean {
    const currentState = this.navigationStateSubject.value;
    return currentState?.dropdownId === dropdownId;
  }
}
