import { Component } from '@angular/core';

@Component({
  selector: 'weni-app-footer',
  standalone: true,
  imports: [],
  templateUrl: './app-footer.component.html',
  styleUrl: './app-footer.component.less'
})
export class AppFooterComponent {

}
