import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { LayoutAdminComponent } from './layouts/layout-admin/layout-admin.component';
import { ShippingSpacesComponent } from './pages/delivery-order/shipping-spaces.component';
import { LayoutAuthComponent } from './layouts/layout-auth/layout-auth.component';
import { AuthGuard } from './core/guards/auth.guard';
import { loggedAdminResolver } from './core/resolvers/administrator/logged-admin.resolver';

export const appRoutes: Routes = [
  {
    path: 'dev',
    loadComponent: () =>
      import('./pages/dev/dev.component').then((module) => module.DevComponent),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },

  {
    path: 'auth',
    component: LayoutAuthComponent,
    loadChildren: () => import('./pages/authentication/routes'),
  },
  {
    path: 'panel',
    canActivate: [AuthGuard],
    component: LayoutAdminComponent,
    resolve: {
      dataAdmin: loggedAdminResolver,
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        data: {
          breadcrumb: 'Tableau de bord',
        },
        loadChildren: () => import('./pages/dashboard/routes'),
      },
      {
        path: 'states',
        data: {
          breadcrumb: 'Etats',
        },
        loadChildren: () => import('./pages/state/routes'),
      },
      {
        path: 'payment-request',
        data: {
          breadcrumb: 'Demande de paiements',
        },
        loadChildren: () => import('./pages/payment-request/routes'),
      },

      {
        path: 'notifications',
        data: {
          breadcrumb: 'Notifications',
        },
        loadChildren: () =>
          import('./notification/notification.module').then(
            (not) => not.NotificationModule
          ),
      },
      {
        // /panel/admins
        path: 'admins',
        data: {
          breadcrumb: 'Administrateurs',
        },
        loadChildren: () => import('./pages/administrators/routes'),
      },
      {
        path: 'account',
        data: {
          breadcrumb: 'Gestion de compte',
        },
        loadChildren: () => import('./pages/manage-account/routes'),
      },

      {
        path: 'referential',

        data: {
          breadcrumb: 'Referentiel',
        },
        loadChildren: () => import('./pages/refrential/routes'),
      },

      {
        path: 'shipments',
        data: {
          breadcrumb: 'Colis',
        },
        loadChildren: () => import('./pages/shipment/route'),
      },

      {
        // /panel/equipments

        path: 'equipments',
        data: {
          breadcrumb: 'Gestion des engins',
        },
        loadChildren: () => import('./pages/manage-equipments/routes'),
      },
      {
        // /panel/settings

        path: 'settings',
        data: {
          breadcrumb: 'Paramètres',
        },
        loadComponent: () =>
          import('./pages/settings/setting-home/setting-home.component').then(
            (module) => module.SettingHomeComponent
          ),
      },
      {
        // /panel/shipping
        path: 'shipping',
        data: {
          breadcrumb: 'Commandes',
        },
        component: ShippingSpacesComponent,
        loadChildren: () => import('./pages/delivery-order/route'),
      },

      {
        path: 'pricing',
        data: {
          breadcrumb: 'Tarifications',
        },
        loadChildren: () => import('./pages/pricing/routes'),
      },

      {
        path: 'security',
        data: {
          breadcrumb: 'Sécurité',
        },
        loadChildren: () => import('./pages/security/routes'),
      },
    ],
  },
];
