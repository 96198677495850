import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuListComponent } from '../menu-list/menu-list.component';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { UtilsService } from 'src/app/core/services/utils.service';
import { AdministratorsService } from 'src/app/core/services/administrators.service';
import { IAdmin } from 'src/app/core/interfaces/iadmin';
import { Subscription } from 'rxjs';
import { CommonModule, NgIf } from '@angular/common';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { ROUTES } from '../menu-list/routes';
@Component({
  selector: 'weni-app-header',
  standalone: true,
  imports: [MenuListComponent, NzPopconfirmModule, RouterModule, CommonModule],
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.less',
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  profile: IAdmin | null = null;
  error: string | null = null;
  loading = true;
  private profileSubscription: Subscription | null = null;

  constructor(
    private service: UtilsService,
    private adminService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.profileSubscription = this.adminService.loggedAdmin$.subscribe(
      (response) => {
        this.profile = response;
      }
    );
  }

  ngOnDestroy(): void {
    this.profileSubscription?.unsubscribe();
  }

  confirm(): void {
    this.service.LogOut();
  }

  goHome() {
    this.router.navigate(ROUTES.dashboard);
  }
}
