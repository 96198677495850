export const ROUTES = {
  dashboard: ['/panel/dashboard/home'],
  'payment-requests': ['/panel/payment-request/all'],
  'payment-requests-details': ['/panel/payment-request/details'],
  admins: ['/panel/admins/all'],
  adminDetail: ['/panel/admins/details'],
  'orders-customers': ['/panel/shipping/customer-orders/list'],
  'orders-suppliers': ['/panel/shipping/supplier-orders/list'],
  'orders-suppliers-details': ['/panel/shipping/supplier-orders/details'],
  'orders-packages': ['/panel/shipments/all'],
  'order-packages-details': ['/panel/shipments/details'],
  'delivery-location': ['/panel/shipments/deliveryman-location'],
  rapport: ['/panel/shipping/report/list'],
  'rapport-details': ['/panel/shipping/report/details'],
  'rapport-day': ['/panel/states/index'],
  boutique: ['/panel/account/shop/home'],
  boutiqueDetails: ['/panel/account/shop/details'],
  fournisseurs: ['/panel/account/shop-accounts/home'],
  commerciaux: ['/panel/account/sales/home'],
  commerciauxDetails: ['/panel/account/sales/details'],
  logisticiens: ['/panel/account/logisticians/home'],
  logisticiensDetails: ['/panel/account/logisticians/details'],
  livreurs: ['/panel/account/drivers/home'],
  livreursDetails: ['/panel/account/drivers/details'],
  'user-mobiles': ['/panel/account/customers/list'],
  userMobileDetails: ['/panel/account/customers/details'],
  'vehicles-list': ['/panel/equipments/engines/all'],
  historiques: ['/panel/equipments/histories'],
  sinistres: ['/panel/equipments/claims'],
  'security-permissions': ['/panel/security/permissions'],
  permissionDetails: ['/panel/security/permissions/details'],
  'profil-management': ['/panel/security/profiles/all'],
  profilDetails: ['/panel/security/profiles/details'],
  area: ['/panel/referential/area/list'],
  areaDetail: ['/panel/referential/area/details'],
  'relance-motif': ['/panel/referential/relaunch/list'],
  relanceMotifDetails: ['/panel/referential/relaunch/details'],
  'return-motif': ['/panel/referential/return/list'],
  returnMotifDetails: ['/panel/referential/return/details'],
  ville: ['/panel/referential/city/all'],
  villeDetails: ['/panel/referential/city/details'],
  pays: ['/panel/referential/countries/all'],
  brand: ['/panel/referential/brand/all'],
  brandDetails: ['/panel/referential/brand/details'],
  // faq: ['/faq'],
  'services-livraison': ['/panel/referential/delivery-services/list'],
  servicesDetails: ['/panel/referential/delivery-services/details'],
  Tarification: ['/panel/pricing/list'],
  TarificationDetails: ['/panel/pricing/details'],
  Settings: ['/panel/settings'],

  parcEngine: ['/panel/equipments/vehicles/all'],
  parcEngineDetails: ['/panel/equipments/vehicles/details'],
  parcHistory: ['/panel/equipments/histories'],
  parcHistoryDetails: ['/panel/equipments/histories/details'],
  parcClaim: ['/panel/equipments/claims'],
  parcClaimDetails: ['/panel/equipments/claims/details'],
};

export interface MenuItem {
  label: string;
  link?: string[];
  id: string;
  subItems?: {
    id: string;
    label: string;
    link?: string[];
    action?: () => void;
    openModal?: boolean;
  }[];
  openModal?: boolean;
  isOpen?: boolean;
}

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 'dashboard',
    label: 'Tableau de bord',
    link: ROUTES.dashboard,
  },

  {
    id: 'orders',
    label: 'Commandes',
    subItems: [
      {
        id: 'orders-customers',
        label: 'Clients',
        link: ROUTES['orders-customers'],
      },
      {
        id: 'orders-suppliers',
        label: 'Fournisseurs',
        link: ROUTES['orders-suppliers'],
      },
      {
        id: 'orders-packages',
        label: 'Colis',
        link: ROUTES['orders-packages'],
      },

      {
        id: 'payment-requests',
        label: 'Demande de paiements',
        link: ROUTES['payment-requests'],
      },
      {
        id: 'rapport',
        label: 'Rapport',
        link: ROUTES.rapport,
      },
      {
        id: 'rapport-day',
        label: 'Rapport journalier',
        link: ROUTES['rapport-day'],
      },
      {
        id: 'delivery-location',
        label: 'Localisation livreur',
        link: ROUTES['delivery-location'],
      },
    ],
  },
  {
    id: 'account',
    label: 'Gestion de compte',
    subItems: [
      {
        id: 'boutique',
        label: 'Boutiques ',
        link: ROUTES.boutique,
      },
      // {
      //   id: 'fournisseurs',
      //   label: 'Boutiques',
      //   link: ROUTES.fournisseurs,
      // },
      {
        id: 'commerciaux',
        label: 'Commerciaux',
        link: ROUTES.commerciaux,
      },
      {
        id: 'logisticiens',
        label: 'Logisticiens',
        link: ROUTES.logisticiens,
      },
      {
        id: 'livreurs',
        label: 'Livreurs',
        link: ROUTES.livreurs,
      },
      {
        id: 'user-mobiles',
        label: 'Utilisateurs mobiles',
        link: ROUTES['user-mobiles'],
      },
    ],
  },
  {
    id: 'vehicles',
    label: 'Parc',
    subItems: [
      {
        id: 'vehicles-list',
        label: 'Liste des engins',
        link: ROUTES.parcEngine,
      },

      {
        id: 'sinistres',
        label: 'Sinistres',
        link: ROUTES.sinistres,
      },
    ],
  },
  {
    id: 'security',
    label: 'Sécurité',
    subItems: [
      {
        id: 'security-permissions',
        label: 'Permissions',
        link: ROUTES['security-permissions'],
      },

      {
        id: 'profil-management',
        label: 'Profils',
        link: ROUTES['profil-management'],
      },

      {
        id: 'admins',
        label: 'Administrateurs',
        link: ROUTES.admins,
      },
    ],
  },
  {
    id: 'reference',
    label: 'Référentiel',
    subItems: [
      {
        id: 'area',
        label: 'Zones',
        link: ROUTES.area,
      },
      {
        id: 'relance-motif',
        label: 'Motifs de relances',
        link: ROUTES['relance-motif'],
      },

      {
        id: 'return-motif',
        label: 'Motifs de retours',
        link: ROUTES['return-motif'],
      },

      {
        id: 'brand',
        label: 'Marque',
        link: ROUTES['brand'],
      },

      {
        id: 'ville',
        label: 'Ville',
        link: ROUTES.ville,
      },
      {
        id: 'pays',
        label: 'Pays',
        link: ROUTES.pays,
      },
    ],
  },

  {
    id: 'services',
    label: 'Services et tarifications',
    subItems: [
      {
        id: 'services-livraison',
        label: 'Services livraison',
        link: ROUTES['services-livraison'],
      },
      {
        id: 'Tarification',
        label: 'Tarifications Zone',
        link: ROUTES.Tarification,
      },
    ],
  },

  {
    id: 'settings',
    label: 'Paramètre',
    link: ROUTES.Settings,
  },
];
