import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DailyReportService {
  constructor(private _http: HttpClient) {}

  deliveryReport(start_date: string = '', end_date: string = '') {
    return this._http
      .get(
        `${environment.baseUrl}/admin/dashboard/delivery-report?start_date=${start_date}&end_date=${end_date}`,
        {
          responseType: 'blob',
          observe: 'response',
        }
      )
      .pipe(
        map((response: HttpResponse<Blob | any>) => response.body),
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            "Une erreur s'est produite lors du téléchargement.";
          if (
            error.error instanceof Blob &&
            error.error.type === 'application/json'
          ) {
            const reader = new FileReader();
            reader.onload = (event) => {
              try {
                const errorResponse = JSON.parse(
                  event?.target?.result as string
                );
                errorMessage = errorResponse.message || errorMessage;
              } catch (e) {
                // Ignore JSON parse error
              }
            };
            reader.readAsText(error.error);
          }
          console.error(errorMessage, error);
          return throwError(new Blob([errorMessage], { type: 'text/plain' })); // Renvoyer une erreur observable sous forme de Blob
        })
      );
  }
  dailyShopReport(start_date: string = '', end_date: string = '') {
    return this._http
      .get(
        `${environment.baseUrl}/admin/dashboard/daily-shop-report?start_date=${start_date}&end_date=${end_date}`,
        {
          responseType: 'blob',
          observe: 'response',
        }
      )
      .pipe(
        map((response: HttpResponse<Blob | any>) => response.body),
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            "Une erreur s'est produite lors du téléchargement.";
          if (
            error.error instanceof Blob &&
            error.error.type === 'application/json'
          ) {
            const reader = new FileReader();
            reader.onload = (event) => {
              try {
                const errorResponse = JSON.parse(
                  event?.target?.result as string
                );
                errorMessage = errorResponse.message || errorMessage;
              } catch (e) {
                // Ignore JSON parse error
              }
            };
            reader.readAsText(error.error);
          }
          console.error(errorMessage, error);
          return throwError(new Blob([errorMessage], { type: 'text/plain' })); // Renvoyer une erreur observable sous forme de Blob
        })
      );
  }
}
