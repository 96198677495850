export const mappingRoutes = {
  dashboard: '/panel/dashboard/home',
  myProfile: 'panel/admins/security/my-profile',
  myProfileUpdatePassword: 'panel/admins/security/my-profile/change-password',
  settings: '/panel/settings',
  notifications: 'panel/notifications',
  auth: {
    login: 'auth/login',
    forgot: {
      email: 'auth/forgot/define-email',
      code: 'auth/forgot/define-code',
      access: 'auth/forgot/define-access',
    },
  },

  states: {
    index: 'panel/states',
  },

  delivery: {
    list: 'delivery/panel/dashboard/home',
  },

  paymentRequest: {
    list: 'panel/payment-request/all',
    details: 'panel/payment-request/show',
  },

  shipment: {
    list: 'panel/shipments/all',
    details: 'panel/shipments/details',
    driverGlobalLocation: 'panel/shipments/driver-location',
  },

  customerOrders: {
    list: '/panel/shipping/customer-orders',
  },
  supplierDelivery: {
    register: '/panel/shipping/delivery/register',
    details: '/panel/shipping/delivery/details',
    list: '/panel/shipping/supplier-orders/list',

    completedDetails: '/panel/shipping/delivery/completed/details',
    inProgress: '/panel/shipping/delivery/progress/details',

    shipment: {
      edit: '/panel/shipping/delivery/edit-shipement',
    },
  },
  report: {
    list: '/panel/shipping/report',
    byShop: '/panel/shipping/report/by-shop/',
  },

  manageAccount: {
    shop: '/panel/account/shop',
    createShop: '/panel/account/shop/create',
    editShop: '/panel/account/shop/edit',
    detailsShop: '/panel/account/shop/details',
    deleteShop: '/panel/account/shop/delete',
    reportShop: '/panel/account/shop/report',
    shopDetails:'/panel/account/shop/home',

    fournisseur: {
      list: '/panel/account/shop-accounts',
      details: '/panel/account/shop-accounts/details',
    },
    //
    drivers: '/panel/account/drivers',
    createDrivers: '/panel/account/drivers/create',
    editDrivers: '/panel/account/drivers/edit',
    detailsDrivers: '/panel/account/drivers/details',
    detailsDriver: '/panel/account/drivers/home',
    deleteDrivers: '/panel/account/drivers/delete',

    reportDrivers: '/panel/account/drivers/report-driver',

    //
    logisticians: '/panel/account/logisticians',
    createLogisticians: '/panel/account/logisticians/create',
    editLogisticians: '/panel/account/logisticians/edit',
    detailsLogisticians: '/panel/account/logisticians/details',
    //
    sales: '/panel/account/sales',
    createSales: '/panel/account/sales/create',
    detailSales: '/panel/account/sales/details',
    editSales: '/panel/account/sales/edit',

    customers: {
      list: 'panel/account/customers',
      details: 'panel/account/customers/details',
    },
  },

  equipments: {
    engine: '/panel/equipments/engines',
    engineDetails: '/panel/equipments/engines/details/',
    registerEngine: '/panel/equipments/engines/register',
    editEngine: '/panel/equipments/engines/edit',
    histories: '/panel/equipments/histories',
    claims: '/panel/equipments/claims',
    claimsDetails: 'panel/equipments/claims/details',
  },

  faq: {
    categories: {
      list: '/panel/faq/categories/all',
      create: '/panel/faq/categories/create',
      edit: '/panel/faq/categories/edit',
    },
    questions: {
      list: '/panel/faq/questions/all',
      create: '/panel/faq/questions/create',
      edit: '/panel/faq/questions/edit',
      details: '/panel/faq/questions/details',
    },
  },

  referential: {
    brands: '/panel/equipments/brands',

    city: '/panel/referential/city',
    cityCreate: '/panel/referential/city/create',
    cityEdit: '/panel/referential/city/edit',

    countries: '/panel/referential/countries',
    countriesCreate: '/panel/referential/countries/create',
    countriesEdit: '/panel/referential/countries/edit',

    deliveryService: {
      list: '/panel/referential/delivery-services/list',
      create: '/panel/referential/delivery-services/create',
      edit: '/panel/referential/delivery-services/edit',
      addTarification: '/panel/referential/delivery-services/add-pricing',
      linkDeliveryAreaPricing:
        '/panel/referential/delivery-services/link-delivery-area-pricing',

      details: '/panel/referential/delivery-services/details',
    },

    relaunch: {
      list: '/panel/referential/relaunch',
      create: '/panel/referential/relaunch/create',
      edit: '/panel/referential/relaunch/edit',
      details: '/panel/referential/relaunch/details',
    },
    return: {
      list: '/panel/referential/return',
      create: '/panel/referential/return/create',
      edit: '/panel/referential/return/edit',
      details: '/panel/referential/return/details',
    },

    area: {
      list: 'panel/referential/area',
      create: 'panel/referential/area/create',
      edit: 'panel/referential/area/edit',
      details: 'panel/referential/area/details',
    },
  },

  tarifications: {
    list: 'panel/pricing',
    create: 'panel/pricing/create',
    edit: 'panel/pricing/edit',
    details: 'panel/pricing/details',
  },

  administrators: {
    createAdmin: '/panel/admins/register',
    detailsAdmin: '/panel/admins/details',
    admins: '/panel/admins/all',
  },

  //Profil & permissions

  profiles: {
    list: '/panel/security/profiles/all',
    create: '/panel/security/profiles/create',
    edit: '/panel/security/profiles/edit',
  },
  permissions: {
    list: '/panel/security/permissions/all',
  },

  //Equipe

  teams: {
    list: '/panel/teams/all',
    create: '/panel/teams/create',
    edit: '/panel/teams/edit',
    details: '/panel/teams/details',
  },

  portal: {
    home: '/home',
    faqs: '/faqs',
    deliveryZone: '/zoneLivraison',
  },
};
