import { Component, OnInit } from '@angular/core';
import { IMenuItem } from '../../core/interfaces/IMenuItem';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { mappingRoutes } from '../../core/mappingRoutes';
import { UtilsService } from 'src/app/core/services/utils.service';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { PageLoadingWidgetComponent } from '../page-loading-widget/page-loading-widget.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ItemSubmenuComponent } from '../../shared/items/item-submenu/item-submenu.component';
import { ItemMenuComponent } from '../../shared/items/item-menu/item-menu.component';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { MenuListComponent } from './components/menu-list/menu-list.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';

@Component({
  selector: 'weni-layout-admin',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.less'],
  standalone: true,
  imports: [
    NzLayoutModule,
    NzSpaceModule,
    NzAvatarModule,
    NzDropDownModule,
    NzMenuModule,
    NzButtonModule,
    NgFor,
    ItemMenuComponent,
    ItemSubmenuComponent,
    NgClass,
    NzDividerModule,
    NzWaveModule,
    NzIconModule,
    NgIf,
    NzGridModule,
    NzFormModule,
    NzInputModule,
    PageLoadingWidgetComponent,
    NzBreadCrumbModule,
    RouterOutlet,
    AppHeaderComponent,
    MenuListComponent,
    NzBreadCrumbModule,
    RouterModule,
    AppFooterComponent,
  ],
})
export class LayoutAdminComponent implements OnInit {
  isOpenNavbar: boolean = false;

  isPageChange: boolean = false;
  constructor(private _router: Router, private _utilService: UtilsService) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isPageChange = true;
      } else if (event instanceof NavigationEnd) {
        this.isPageChange = false;
      }
    });
  }

  ngOnInit(): void {}

  logout() {
    this._utilService.LogOut();
  }

  goParameter() {
    this._router.navigate([mappingRoutes.settings]);
  }

  goMyProfil() {
    this._router.navigate([mappingRoutes.myProfile]);
  }
  toggleNavbar() {
    this.isOpenNavbar = !this.isOpenNavbar;
  }

  goNotifications() {
    this._router.navigate([mappingRoutes.notifications]);
  }

  onInput(event: Event) {}

  get currentUrl(): string {
    return window.location.pathname;
  }
}
