import { Component } from '@angular/core';
import { StateIndexTabComponent } from '../components/state-index-tab/state-index-tab.component';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DailyReportService } from 'src/app/core/services/daily-report.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { formatCustomStartDate } from 'src/app/core/utils/date-convertor';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NgFor } from '@angular/common';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { PrimaryActionButtonComponent } from '../../../shared/ui/primary-action-button/primary-action-button.component';
import { CustomDateRangePickerComponent } from '../../../shared/custom-date-range-picker/custom-date-range-picker.component';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'weni-state-index',
  templateUrl: './state-index.component.html',
  styleUrls: ['./state-index.component.less'],
  standalone: true,
  imports: [
    NzTabsModule,
    NzSpaceModule,
    StateIndexTabComponent,
    ReactiveFormsModule,
    NzFormModule,
    NzGridModule,
    NzButtonModule,
    NzInputModule,
    NzSelectModule,
    NgFor,
    NzDatePickerModule,
    FormsModule,
    NzWaveModule,
    PrimaryActionButtonComponent,
    CustomDateRangePickerComponent,
  ],
})
export class StateIndexComponent implements OnInit {
  REPORT_TYPES: Array<any> = [
    {
      label: 'Rapport(csv) des ventes par boutiques par jour',
      value: 'DETAILS_SHOP_REPORT',
    },
    {
      label: 'Rapport(csv) des livraisons par jours',
      value: 'DELIVERY_DAILY_REPORT',
    },
  ];

  form: FormGroup;
  reportByDate: boolean = false;
  isLoading: boolean = false;
  selectedDateRange: string = '';
  start_date: string = '';
  end_date: string = '';
  constructor(
    private fb: FormBuilder,
    private _service: DailyReportService,
    private msgService: NzMessageService
  ) {
    this.form = this.fb.group({
      reportDate: new FormControl('', []),
      reportType: new FormControl('', [Validators.required]),
    });
  }
  ngOnInit(): void {
    this.form.valueChanges.subscribe((value) => {
      if (value.reportByDate) {
        this.reportByDate = true;
      } else {
        this.reportByDate = false;
      }
    });
  }

  onFilterWithDate(e: any) {
    this.start_date = formatCustomStartDate(e.start);
    this.end_date = formatCustomStartDate(e.end);
  }

  onSubmit() {
    const deliveryType = this.form.getRawValue().reportType;
    this.isLoading = true;

    let action;

    if (deliveryType === 'DELIVERY_DAILY_REPORT') {
      action = this._service.deliveryReport(this.start_date, this.end_date);
    } else {
      action = this._service.dailyShopReport(this.start_date, this.end_date);
    }

    action.subscribe((blob) => {
      this.isLoading = false;

      if (blob instanceof Blob) {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = `rapport_${Date.now()}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);

        this.msgService.success('Exportation des données effectuées');
      }
    });
  }
}
