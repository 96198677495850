import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DropdownService } from 'src/app/layouts/layout-admin/components/menu-list/components/services/dropdown.service';
import { LocalStorageServiceService } from 'src/app/layouts/layout-admin/components/menu-list/components/services/local-storage-service.service';
import {
  MENU_ITEMS,
  MenuItem,
} from 'src/app/layouts/layout-admin/components/menu-list/routes';

@Component({
  selector: 'weni-mobile-menu',
  standalone: true,
  imports: [CommonModule, NzButtonModule, NzIconModule, RouterModule],
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.less',
})
export class MobileMenuComponent {
  menuItems: MenuItem[] = MENU_ITEMS;
  @Output() menuItemClicked = new EventEmitter<MenuItem>();
  @Output() menuClosed = new EventEmitter<void>();
  activeSubMenuId: string | null = null;
  activeMenuId: string | null = null;
  constructor(
    private router: Router,
    private service: UtilsService,
    private drawerRef: NzDrawerRef,
    private localStorageService: LocalStorageServiceService,
    private cdr: ChangeDetectorRef,
    private dropdownService: DropdownService
  ) {}

  ngOnInit() {
    this.localStorageService.getNavigationState$().subscribe((state) => {
      if (state) {
        this.activeSubMenuId = state.dropdownId;
        this.activeMenuId = state.menuId;
      } else {
        this.activeSubMenuId = null;
        this.activeMenuId = null;
      }
      this.cdr.markForCheck();
    });
  }
  toggleSubMenu(item: MenuItem) {
    // Toggle the isOpen state for the clicked item
    item.isOpen = !item.isOpen;

    // Close other open submenus
    this.menuItems.forEach((menuItem) => {
      if (menuItem !== item && menuItem.subItems) {
        menuItem.isOpen = false;
      }
    });
  }

  onMenuItemClick(item: MenuItem) {
    this.router.navigate([item.link]);
    this.drawerRef.close();
  }

  onSubMenuItemClick(subItem: MenuItem) {
    if (subItem && subItem.link) {
      this.router.navigate(subItem.link);
      this.drawerRef.close();
    }
  }

  handleSubItemClick(dropdown: MenuItem, item: MenuItem) {
    this.dropdownService.setActiveDropdown(dropdown.id);
    this.localStorageService.setNavigationState(dropdown.id, item.id);
    this.drawerRef.close();

    this.cdr.markForCheck();
  }

  confirm(): void {
    this.drawerRef.close();
    this.service.LogOut();
  }
}
