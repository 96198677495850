<button
  #buttonRef
  [disabled]="disabled() || isLoading()"
  [class]="computedClasses()"
  (mousedown)="onMouseDown($event)"
  (mouseup)="onMouseUp()"
  (mouseleave)="onMouseLeave()"
  [attr.type]="type()"
  [attr.aria-busy]="isLoading()"
>
  <div class="flex items-center gap-2 relative">
    @if (isLoading()) {
    <svg
      class="animate-spin -ml-1 mr-2"
      [class.h-4]="size() === 'sm'"
      [class.w-4]="size() === 'sm'"
      [class.h-5]="size() === 'md'"
      [class.w-5]="size() === 'md'"
      [class.h-6]="size() === 'lg'"
      [class.w-6]="size() === 'lg'"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    } @if (leftIcon()) {
    <span class="icon-container" [innerHTML]="leftIcon()"></span>
    }

    <span [class.opacity-90]="isLoading()">{{ label() }}</span>

    @if (rightIcon()) {
    <span class="icon-container" [innerHTML]="rightIcon()"></span>
    }

    <div #rippleElement class="ripple absolute pointer-events-none"></div>
  </div>
</button>
