<nav class="!bg-none shadow-sm">
  <!-- Mobile & Tablet View -->
  <div class="lg:hidden">
    <div class="flex items-center justify-between p-4 px-2">
      <a class routerLink="/panel/dashboard" class="font-semibold !text-black"
        >Votre Logo</a
      >
      <button
        (click)="toggleMobileMenu()"
        class="p-2 rounded-md hover:bg-gray-100 focus:outline-none"
      >
        <ng-container *ngIf="!isOpen">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </ng-container>
        <ng-container *ngIf="isOpen">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            /></svg
        ></ng-container>
      </button>
    </div>
  </div>

  <!-- Desktop View -->
  <div class="hidden lg:block w-full">
    <div
      class="container mx-auto overflow-x-auto sm:overflow-hidden w-full !px-0 sm:px-2 md:px-6"
    >
      <div class="relative flex items-center justify-center">
        <ul class="flex flex-nowrap py-4">
          <li
            *ngFor="let item of menuItems"
            class="flex-none mx-1 first:ml-0 last:mr-0 relative group"
          >
            <ng-container *ngIf="!item.subItems">
              <weni-menu-item [item]="item"></weni-menu-item>
            </ng-container>

            <div
              style="z-index: 1000 !important"
              *ngIf="item.subItems"
              class="inline-block -mt-2 relative"
            >
              <weni-sub-menu-item
                [isActive]="activeMenuId === item.id"
                (menuToggled)="handleMenuToggle(item.id)"
                [item]="item"
                (itemClicked)="handleMenuItemClick($event)"
              ></weni-sub-menu-item>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
