<div class="inline-block text-left">
  <button
    #triggerButton
    (click)="toggleDropdown($event)"
    class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none"
    [ngClass]="{
      '!bg-primary !text-white !rounded-lg ': item.id === activeMenuId
    }"
  >
    {{ item.label }}
    <svg
      class="w-4 h-4 ml-2 transition-transform duration-200"
      [class.rotate-180]="isActive"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clip-rule="evenodd"
      />
    </svg>
  </button>

  <div
    *ngIf="isActive"
    #dropdownMenu
    class="fixed z-50 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition-opacity duration-200"
    [class.opacity-0]="!isPositioned"
    [style.top.px]="dropdownTop"
    [style.left.px]="dropdownLeft"
  >
    <div class="py-1">
      <a
        *ngFor="let subItem of item.subItems"
        [routerLink]="subItem.link"
        class="block px-4 py-2 text-sm text-gray-700 hover:bg-primary-200 hover:text-primary hover:rounded-sm transition-colors duration-150"
        [ngClass]="{
          '!bg-primary !text-white  ': subItem.id === activeSubMenuId
        }"
        (click)="handleSubItemClick(subItem.id)"
      >
        {{ subItem.label }}
      </a>
    </div>
  </div>
</div>
