import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { Subscription } from 'rxjs';
import { DropdownService } from '../services/dropdown.service';
import { LocalStorageServiceService } from '../services/local-storage-service.service';

@Component({
  selector: 'weni-sub-menu-item',
  standalone: true,
  imports: [CommonModule, NzIconModule, NzDropDownModule, RouterModule],
  templateUrl: './sub-menu-item.component.html',
  styleUrl: './sub-menu-item.component.less',
})
export class SubMenuItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item!: any;
  @Input() isActive = false;
  activeMenuId: string | null = null;
  @Output() menuToggled = new EventEmitter<void>();
  //@Output() activeSubmenuEvent = new EventEmitter<string>();
  @ViewChild('triggerButton') triggerButton!: ElementRef;
  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;
  private subscription = new Subscription();
  isPositioned = false;
  dropdownTop = 0;
  dropdownLeft = 0;
  activeSubMenuId: string | null = null;
  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private dropdownService: DropdownService,
    private localStorageService: LocalStorageServiceService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.localStorageService.getNavigationState$().subscribe((state) => {
        if (state) {
          this.activeSubMenuId = state.dropdownId;
          this.activeMenuId = state.menuId;
        } else {
          this.activeSubMenuId = null;
          this.activeMenuId = null;
        }
        this.cdr.markForCheck();
      })
    );
    // Ajouter le listener pour le backdrop
    document.addEventListener('click', this.handleBackdropClick.bind(this));
  }

  ngOnChanges() {
    if (this.isActive) {
      // Reset position state
      this.isPositioned = false;
      this.cdr.detectChanges();
      this.cdr.markForCheck();
      // Recalculate position
      setTimeout(() => {
        this.positionDropdown();
      }, 0);
    }
  }

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.menuToggled.emit();
  }

  handleSubItemClick(dropdownId: string) {
    this.menuToggled.emit();
    this.dropdownService.setActiveDropdown(dropdownId);
    this.localStorageService.setNavigationState(dropdownId, this.item.id);
    this.cdr.markForCheck();
  }
  private positionDropdown() {
    if (
      !this.dropdownMenu?.nativeElement ||
      !this.triggerButton?.nativeElement
    ) {
      return;
    }

    const buttonRect = this.triggerButton.nativeElement.getBoundingClientRect();
    const dropdownRect =
      this.dropdownMenu.nativeElement.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    const spaceAbove = buttonRect.top;
    const spaceBelow = viewportHeight - buttonRect.bottom;
    const padding = 8;

    const showAbove =
      spaceBelow < dropdownRect.height + padding &&
      spaceAbove > dropdownRect.height + padding;

    if (showAbove) {
      this.dropdownTop = buttonRect.top - dropdownRect.height - padding;
    } else {
      this.dropdownTop = buttonRect.bottom + padding;
    }

    let left = buttonRect.left;
    const rightOverflow = left + dropdownRect.width - window.innerWidth;
    if (rightOverflow > 0) {
      left -= rightOverflow + padding;
    }

    this.dropdownLeft = Math.max(padding, left);
    this.isPositioned = true;
    this.cdr.detectChanges();
  }

  private handleBackdropClick = (event: MouseEvent) => {
    // Vérifier si le clic est en dehors du dropdown et du bouton
    if (
      this.isActive &&
      !this.elementRef.nativeElement.contains(event.target)
    ) {
      this.dropdownService.closeAllDropdowns();
      this.menuToggled.emit();
    }
  };

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    document.removeEventListener('click', this.handleBackdropClick.bind(this));
  }

  @HostListener('window:resize')
  @HostListener('window:scroll')
  onViewportChange() {
    if (this.isActive) {
      this.positionDropdown();
    }
  }
}
