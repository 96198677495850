<div class="date-picker-container" [class.bg-white]="variant === 'white'">
  <!-- Champ de saisie -->
  <div
    class="input-container px-2 flex items-center rounded-md"
    [class.!bg-white]="variant === 'white'"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-calendar"
    >
      <path d="M8 2v4" />
      <path d="M16 2v4" />
      <rect width="18" height="18" x="3" y="4" rx="2" />
      <path d="M3 10h18" />
    </svg>
    <input
      type="text"
      [value]="formatDateRange()"
      readonly
      (click)="toggleCalendar()"
      placeholder="Sélectionnez une période"
      class="date-input focus:!outline-none text-xs"
    />
  </div>

  <!-- Popup du calendrier -->
  <div
    class="calendar-popup"
    *ngIf="isCalendarVisible"
    [style.top]="'100%'"
    [style.left]="'0'"
  >
    <div class="calendar-header">
      <button (click)="previousMonth()">&lt;</button>
      <div class="month-year-selector">
        <select [(ngModel)]="currentMonth" (change)="onMonthChange()">
          <option *ngFor="let month of months; let i = index" [value]="i">
            {{ month }}
          </option>
        </select>
        <select [(ngModel)]="currentYear" (change)="onYearChange()">
          <option *ngFor="let year of years" [value]="year">
            {{ year }}
          </option>
        </select>
      </div>
      <button (click)="nextMonth()">&gt;</button>
    </div>

    <div class="weekdays">
      <div *ngFor="let day of weekDays">{{ day }}</div>
    </div>

    <div class="calendar-grid">
      <div
        *ngFor="let day of calendarDays"
        class="calendar-day"
        [class.disabled]="!day.enabled"
        [class.selected]="isSelected(day.date)"
        [class.in-range]="isInRange(day.date)"
        [class.today]="isToday(day.date)"
        (click)="selectDate(day)"
      >
        {{ day.dayNumber }}
      </div>
    </div>

    <div class="calendar-footer">
      <button class="today-btn" (click)="setToday()">Today</button>
      <button class="clear-btn" (click)="clearSelection()">Clear</button>
    </div>
  </div>
</div>
