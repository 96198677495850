<div class="w-full relative h-full">
  <div class="text-2xl font-bold mb-4 text-center">Votre Logo</div>

  <ng-container *ngFor="let item of menuItems">
    <!-- Regular menu items -->
    <a
      *ngIf="!item.subItems"
      [routerLink]="item.link"
      routerLinkActive="text-white bg-primary"
      [ngClass]="{
        '!bg-primary !text-white !rounded-lg ': item.id === activeMenuId
      }"
      class="block px-4 py-2 text-gray-900 hover:bg-primary-100 hover:text-primary-400 transition-colors duration-200"
      (click)="onMenuItemClick(item)"
    >
      {{ item.label }}
    </a>

    <!-- Items with submenu -->
    <div *ngIf="item.subItems" class="relative">
      <button
        [ngClass]="{
          '!bg-primary !text-white !rounded-lg ': item.id === activeMenuId
        }"
        (click)="toggleSubMenu(item)"
        class="w-full px-4 py-2 text-left text-gray-900 hover:bg-gray-50 flex justify-between items-center transition-colors duration-200"
      >
        {{ item.label }}
        <svg
          class="w-5 h-5 transform transition-transform duration-200"
          [class.rotate-180]="item.isOpen"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <div
        *ngIf="item.isOpen"
        class="bg-gray-50 overflow-hidden transition-all duration-300 ease-in-out"
      >
        <a
          [ngClass]="{
            '!bg-primary !text-white !rounded-lg  ':
              subItem.id === activeSubMenuId
          }"
          *ngFor="let subItem of item.subItems"
          (click)="handleSubItemClick(subItem, item)"
          [routerLink]="subItem.link"
          class="block pl-8 pr-4 py-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-400 cursor-pointer transition-colors duration-200"
        >
          {{ subItem.label }}
        </a>
      </div>
    </div>
  </ng-container>

  <div class="bottom-0 left-0 w-full flex justify-end items-center absolute">
    <div class="w-full flex justify-end items-end p-4">
      <button (click)="confirm()" nz-button class="flex items-center gap-2">
        <span nz-icon nzType="logout" nzTheme="outline"></span>
        <span>Quitter</span>
      </button>
    </div>
  </div>
</div>
